
.stats-section {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-rows: 120px 370px 340px repeat(2, 380px);
    grid-gap: 24px 18px;
}

.chart-box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 16px;
    flex: 1;

    header {
        margin-bottom: 8px;
        color: var(--card-header-color);
    }

    &__value {
        font-size: 28px;
    }

    footer {
        opacity: .3;
    }
}

.card-stats_wrap {
    display:flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    width: 100%;
    height: 100%;

    .card-stats {
        position: relative;
        height: 100%;
        box-sizing: border-box;
        
        & + .card-stats {
            margin-top: 0;
            margin-left: 24px;
        }
    }
}
.card-stats__chart {
    flex: 1;
}
.card-stats__side-ear {
    width: 220px;
}

@for $i from 0 through 3 {
    .information-block[data-index='#{$i}'] {
        grid-column: #{$i * 3 + 1} / span 3;
    }
}

.ton-icon {
    fill: currentColor;
    width: 28px;
    height: 28px;
    margin-right: 2px;
    opacity: .3;
    transform: translate(-3px, 3px);
}

.cotract-types-container {
    grid-column: 7 / 13;
}

.address-count-container {
    grid-column: 1 / 7;
}

.price-container,
.transaction-count-container,
.staking-container,
.validation-status-container {
    grid-column: 1 / 13;
}

@media screen and (max-width: 1099px) {
    .stats-section {
        grid-template-rows: 108px 108px 400px 360px repeat(2, 300px) 400px auto;
    }

    .information-block[data-index='0'],
    .information-block[data-index='2'] {
        grid-column: 1 / 7;
    }

    .information-block[data-index='1'],
    .information-block[data-index='3'] {
        grid-column: 7 / 13;
    }

    .cotract-types-container,
    .address-count-container {
        grid-column: 1 / 13;
    }
    .card-stats__side-ear {
        width: 170px;
    }
}

.data-container {
  display: flex;
  height: 100%;
  flex-direction: row;
}

@media screen and (max-width: 599px) {
    .data-container {
        flex-direction: column;

        .chart-bar {
            max-height: calc(100% - 93px);
        }
    }
    
    .stats-section {
        grid-template-rows: repeat(4, 108px) 320px 512px repeat(2, 280px) 340px auto;
        grid-gap: 14px;
    }

    .information-block[data-index='0'],
    .information-block[data-index='1'],
    .information-block[data-index='2'],
    .information-block[data-index='3'] {
        grid-column: 1 / 13;
    }

    .card-stats_wrap {
        display: block;

        .card-stats {
            display: flex;
            flex: 1;
            height: auto;
        }

        .card-stats + .card-stats {
            margin-left: 0;
            margin-top: 14px;
        }

        .card-stats__chart {
            width: 100%;
            display: block;
        }
        .card-stats__side-ear {
            width: 100%;
            height: 90px;
            display: block;
        }

        .data-container {
            .chart-bar {
                max-height: 100%;
            }
        }
    }

    .address-count-container {
        grid-row: 7;
    }
    .cotract-types-container {
        grid-row: 6;
    }
}
